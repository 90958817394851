const REACT_APP_API = "https://stg-api.imedis.io";
export const firebaseConfig = {
    apiKey: "AIzaSyCqw4U9NfU_Q8_J_QJzKBDLVM1XpVSCYmc",
    authDomain: "covit-cms.firebaseapp.com",
    databaseURL: "https://covit-cms.firebaseio.com",
    projectId: "covit-cms",
    storageBucket: "covit-cms.appspot.com",
    messagingSenderId: "571825289045",
    appId: "1:571825289045:web:28dc082b8d65fa25ff61dc",
    measurementId: "G-91HDNXHL3S",
};
export const ACCESS_TOKEN = "access_token";
export const SESSION_ACCESS_TOKEN = "session_access_token";
export const REFRESH_TOKEN = "refresh_token";
export const DEVICE_ID = "deviceid";
export const ROLES = "roles";
export const PAGE_COUNT = 30;

export const AUTH_URL = `${REACT_APP_API}/web/Profile/login`;
export const REFRESH_URL = `${REACT_APP_API}/web/Profile/token/refresh`;
export const GET_USER_PROFILE = `${REACT_APP_API}/web/Profile/me`;
export const POST_USER_PROFILE = `${REACT_APP_API}/web/CmsProfile/me`;
export const GET_CMS_USERS = `${REACT_APP_API}/web/CmsProfile`;
export const GET_USER_BY_EMAIL = `${REACT_APP_API}/web/CmsProfile/searchWebProfile`;
export const USER_ASSIGN = `${REACT_APP_API}/web/CmsProfile/AssignToWebProfile`;
export const USER_UNASSIGN = `${REACT_APP_API}/web/CmsProfile/UnassignFromWebProfile`;
export const USERS_FOR_MODULE_LIST = `${REACT_APP_API}/web/CmsProfile/List`;
export const FORGOT_PASSWORD = `${REACT_APP_API}/web/Profile/ForgotPassword`;
export const CHANGE_PASSWORD = `${REACT_APP_API}/web/Profile/ChangePassword`;
export const CHANGE_PASSWORD_FORCED = `${REACT_APP_API}/web/Profile/login/changePassword`;

export const CMS_SCHEDULE = `${REACT_APP_API}/web/Schedule`;

export const GET_READINGS = `${REACT_APP_API}/web/MedModule/GetDetails`;
export const POST_READINGS = `${REACT_APP_API}/web/MedModule/AddOrUpdateModuleReadings`;
export const POST_ANAMNESIS = `${REACT_APP_API}/web/MedModule/AddOrUpdateAnamnesis`;

export const SETTINGS_URL = `${REACT_APP_API}/web/Config`;

export const GET_PATIENTS_URL = `${REACT_APP_API}/web/Profile`;
export const REGISTER_PATIENT = `${REACT_APP_API}/web/profile/register`;
export const EDIT_PATIENT = `${REACT_APP_API}/web/Profile/Update`;
export const EDIT_PATIENT_IDS = `${REACT_APP_API}/web/Profile/UpdateId`;
export const POST_PATIENT_ANAMNESIS = `${REACT_APP_API}/web/MedModule/SaveAnamnesisRecords`;
export const POST_PATIENT_READINGS = `${REACT_APP_API}/web/MedModule/SaveModuleRecords`;
export const POST_UPDATE_RAPIDTEST_RECORD = `${REACT_APP_API}/web/MedModule/UpdateModuleRecordRapidTest`;
export const PATIENTS_RESET_PIN = `${REACT_APP_API}/web/Profile/ResetPin`;
export const GET_PATIENT_BY_HEALTH_ID = `${REACT_APP_API}/web/Profile/search`;
export const PATIENT_ASSIGN = `${REACT_APP_API}/web/Profile/assign`;
export const PATIENT_UNASSIGN = `${REACT_APP_API}/web/Profile/unassign`;

export const NOTIFICATIONS = `${REACT_APP_API}/web/Notification`;
export const UPGRADE_NOTIFICATION = `${NOTIFICATIONS}/update`;
export const NOTIFICATION_TO_ALL = `${NOTIFICATIONS}/devices`;
export const NOTIFICATION_TO_USER = `${NOTIFICATIONS}/users`;
export const NOTIFICATION_TO_MODULE = `${NOTIFICATIONS}/MedModule`;

export const ORGANIZATIONS = `${REACT_APP_API}/web/Organization`;
export const REGION = `${REACT_APP_API}/web/Region`;
export const COUNTRIES = `${REACT_APP_API}/web/Region/Countries`;
export const TEMPLATES = `${REACT_APP_API}/web/MedModule/ReadingTemplates`;
export const TEMPLATE = `${REACT_APP_API}/web/MedModule/ReadingTemplate`;

export const GET_PATIENT_LATEST_RECORDS = `${REACT_APP_API}/web/MedModule/GetDetailsWithUserData`;
export const GET_PATIENT_LATEST_PRIMARY_RECORDS = `${REACT_APP_API}/web/MedModule/GetLastRecordsForPrimaryReadings`;
export const GET_PATIENT_READING = `${REACT_APP_API}/web/MedModule/GetRecordsForModuleReading`;
export const GET_PATIENT_READINGS = `${REACT_APP_API}/web/MedModule/GetRecordsForMedModuleReadings`;
export const REVIEW_READING =  `${REACT_APP_API}/web/MedModule/ReviewReading`;
export const POST_USER_CSV_EXPORT = `${REACT_APP_API}/web/MedModule/GetRecordsForMedModuleReadingsExport`;
export const POST_MODULE_CSV_EXPORT = `${REACT_APP_API}/web/MedModule/GetRecordsForMedModuleReadingsExport`;

export const CHAT = `${REACT_APP_API}/web/Chat`;
export const CONVERSATION = `${REACT_APP_API}/web/Chat/{chatId}/conversation`;
export const CLOSE_CONVERSATION = `${REACT_APP_API}/web/Chat/{chatId}/conversation/{userId}/close/{lastId}`;
export const ASSIGN_CONVERSATION = `${REACT_APP_API}/web/Chat/{chatId}/conversation/{userId}/assign/{lastId}`;
export const UNASSIGN_CONVERSATION = `${REACT_APP_API}/web/Chat/{chatId}/conversation/{userId}/unassign/{lastId}`;

export const UPLOAD_IMAGE = `${REACT_APP_API}/web/file`;
export const UPLOAD_ANIMATION = `${REACT_APP_API}/web/file/json`;

export const SOS_SIGNALS = `${REACT_APP_API}/web/Profile/SosCalls`;
export const UPLOAD_IMAGE_AND_CREATE_THUMBNAIL = `${REACT_APP_API}/web/file/UploadReadingImgAndCreateThumb`;
export const SOS_SIGNAL_MARK_AS_DONE = `${REACT_APP_API}/web/Profile/MarkSosDone/{id}`;

export const MED_MODULES = `${REACT_APP_API}/web/MedModule`;
export const GET_MED_MODULE = `${REACT_APP_API}/web/MedModule/GetDetails/{id}`;
export const POST_MED_MODULE = `${REACT_APP_API}/web/MedModule/AddOrUpdateMedModule`;

export const RECORD_IMG = `${REACT_APP_API}/web/Image`;

export const MODULE_ID = "2";
export const CHAT_POOL_INTERVAL = 30000;
